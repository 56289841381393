import React from 'react';
import { Link, HeartSmily, SlidingArrow, ConnectToggle, OnVisible } from 'components';
import * as styles from './styles.module.scss';
import GrowingHeardJson from '../../components/ConnectBannerAnimations/GrowingHeardJson';

const ConnectBanner = ({ data }) => {
  const {
    primary: { title1: title, link_url: linkUrl, link_text: linkText },
  } = data;

  return (
    <section className={styles.background}>
      <OnVisible visibleClassName="banner-visible" threshold="1">
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <h3>{title.text}</h3>
            <Link className={styles.link} withArrow to={linkUrl.url}>
              {linkText.text}
            </Link>
          </div>
        </div>
        <div className={styles.animationContainer}>
          <div className={styles.animationContainerTop}>
            <HeartSmily />
            <img
              className={styles.connectBubble}
              src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/fa92c189-7927-4e78-9662-2115b8179d9e_connect-bubble.svg"
              alt="Connect bubble"
            />
            <SlidingArrow />
          </div>
          <div className={styles.animationContainerBottom}>
            <ConnectToggle />
            <GrowingHeardJson />
            <img
              src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/68381029-06f7-413d-87e7-c1e5d622906f_green-gradient-ball.svg"
              alt="Gradient green ball"
            />
          </div>
        </div>
      </OnVisible>
    </section>
  );
};

export default ConnectBanner;
